<template>
  <div class="about-us">
    <Header activeTit="关于我们"></Header>
    <div class="banner">
      <img src="./../assets/img/1.jpeg" alt="">
    </div>
    <div class="mid">
      <div class="tit"></div>
      <div class="tabs">
        <div :class="[ activeItem == '公司' ? 'active' : '', 'tab' ]" @click="checkItem('公司')">公司</div>
        <div :class="[ activeItem == '资质荣誉' ? 'active' : '', 'tab' ]" @click="checkItem('资质荣誉')">资质荣誉</div>
      </div>
      <div class="content" v-if="activeItem == '公司'">
        <div class="title">我们是谁？</div>
        <div class="blue_line">
          <div class="box"></div>
          <div class="line"></div>
          <div class="box"></div>
        </div>
        <div class="c_top">
          <div class="text_box">
            <div class="text">北京帕斯泰克科技有限公司是一家集研发、生产和销售于一体的科技型公司，是专业的中医脉诊仪供应商和脉诊大数据服务商。公司总部位于北京市海淀区，生产基地位于天津市武清区，是北京市中关村高新技术企业。</div>
            <div class="text">公司拥有强大的科研力量，在获得中国中医科学院科研成果转化的基础上，与清华大学和天津中医药大学等国内顶级科研机构密切合作。</div>
            <div class="text">2016年10月，公司与天津中医药大学共建“创新中医联合实验室”，该实验室拥有津京高校科技创新园1100多平米场地，主要开展中医创新理论和应用研究，中医脉诊客观化研究与应用，中西医结合的医疗（中医诊疗）仪器的研发和应用以及中医健康管理工程的研究与应用等。</div>
          </div>
          <div class="img_box">
            <img src="./../assets/img/大厦.png" alt="">
            <div class="cover"></div>
          </div>
        </div>
        <div class="title bt">历史节点</div>
        <div class="blue_line bl">
          <div class="box"></div>
          <div class="line"></div>
          <div class="box"></div>
        </div>
        <div class="steps">
          <div class="step_top">
            <div class="arrow"></div>
            <div class="a_line"></div>
            <div class="group" v-for="k in 6" :key="k">
              <div class="a_circle"></div>
              <div class="a_line short"></div>
            </div>
          </div>
          <div class="texts r_text1">
            <div class="date">2020.07</div>
            <div>三探头中医脉诊仪获得医疗器械注册证，正式上市销售</div>
          </div>
          <div class="texts r_text2">
            <div class="date">2016.04</div>
            <div>成立公司，开始进行商业化推广</div>
          </div>
          <div class="texts r_text3">
            <div class="date">2015.05</div>
            <div>成立课题组，确定研究方向</div>
          </div>
          <div class="texts l_text1">
            <div>开始投入社区版小型脉诊仪研发</div>
            <div class="date">2018.01</div>
          </div>
          <div class="texts l_text2">
            <div>三探头中医脉诊仪问世</div>
            <div class="date">2015.08</div>
          </div>
          <div class="texts l_text3">
            <div>脉诊客观化平台投用</div>
            <div class="date">2010.07</div>
          </div>
        </div>
      </div>
      <div class="content" v-if="activeItem == '资质荣誉'">
        <div class="title">企业荣誉及资质</div>
        <div class="blue_line">
          <div class="box"></div>
          <div class="line"></div>
          <div class="box"></div>
        </div>
        <div class="imgs">
          <img src="./../assets/img/企业荣誉.png" alt="">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layout/Header'
import Footer from './layout/Footer'
export default {
  name: 'about',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      activeItem: '公司'
    }
  },
  methods: {
    checkItem: function (item) {
      this.activeItem = item
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../assets/css/basic.styl'
.about-us
  .banner
    & img
      width 100%
      height 5.58rem
  .mid
    text-align center
    // transform translateY(-6.25rem)
    margin -5.25rem auto 1rem
    position relative
    z-index 1000
    color #fff
    .tit
      font-size 0.8rem
      margin-bottom 1rem
      height 0.8rem
    .tabs
      display flex
      justify-content center
      cursor pointer
      .tab
        width 2.19rem
        height 0.7rem
        line-height 0.7rem
        border 1px solid #fff
        font-size 0.27rem
      .tab:nth-child(1)
        margin-right 0.4rem
      .active
        background #004ea2
        position relative
      .active:after
        position absolute
        top 0.4rem
        left 0.9rem
        content ''
        width 0
        height 0
        border-bottom 0.3rem solid #fff
        border-top 0.3rem solid transparent
        border-left 0.2rem solid transparent
        border-right 0.2rem solid transparent
    .content
      width 13.96rem
      margin 0.3rem auto 0
      border 0.01rem solid #ccc
      border-top none
      background #fff
      padding-top 0.6rem
      padding-bottom 1.3rem
      color #333
      min-height 5rem
      .title
        font-size 0.32rem
        letter-spacing 0.2rem
        font-weight bold
      .bt
        margin-top 1rem
      .blue_line
        width 0.9rem
        height 0.07rem
        margin 0.42rem auto 0.58rem
        display flex
        .box
          width 0.05rem
          background #004ea2
        .box:nth-child(1)
          margin-right 0.15rem
        .line
          width 0.5rem
          background #004ea2
          margin-right 0.15rem
      .bl
        margin 0.95rem auto 0.65rem
      .c_top
        display flex
        .text_box
          width 7.86rem
          margin-left 0.6rem
          margin-right 0.5rem
          text-align left
          .text
            line-height 0.35rem
            text-indent 2em
            margin-bottom 0.5rem
            font-size $fontSmall
        .img_box
          margin-top -0.5rem
          width 4.5rem
          height 5.2rem
          border 0.05rem solid #dcdcdc
          position relative
          & img
            width 4.5rem
            height 5.2rem
            transform translate(0.2rem, -0.3rem)
          .cover
            position absolute
            left 0
            bottom 0
            width 0.65rem
            height 2.05rem
            background #ffffff
            z-index 100
      .steps
        width 13rem
        height 5.75rem
        margin 0 auto
        text-align center
        position relative
        font-size 0.2rem
        .step_top
          .arrow
            width 0.36rem
            height 0.24rem
            margin 0 auto
          .arrow:after
            width 0
            height 0
            content ''
            border-top 0.24rem solid transparent
            border-bottom 0.24rem solid #004ea2
            border-left 0.18rem solid transparent
            border-right 0.18rem solid transparent
          .a_line
            width 0.07rem
            height 0.7rem
            background #004ea2
            margin 0.18rem auto 0
          .group
            .a_circle
              width 0.2rem
              height 0.2rem
              border-radius 50%
              border 0.02rem solid #004ea2
              margin 0.02rem auto
            .short
              height 0.5rem
              margin 0 auto
        .texts
          display flex
          .date
            width 1rem
            height 0.3rem
            line-height 0.3rem
            border-bottom 0.01rem dotted #ccc
            text-align center
            color #004ea2
            margin 0 0.1rem
        .r_text1
          position absolute
          top 1rem
          left 6.8rem
        .r_text2
          position absolute
          top 2.5rem
          left 6.8rem
        .r_text3
          position absolute
          top 4rem
          left 6.8rem
        .l_text1
          position absolute
          top 1.75rem
          right 6.8rem
        .l_text2
          position absolute
          top 3.25rem
          right 6.8rem
        .l_text3
          position absolute
          top 4.75rem
          right 6.8rem
      .imgs
        & img
          width 13.9rem
</style>
